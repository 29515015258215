<template>
    <main class="h-100 w-100">
      <h4 class="mt-2">This is a temporary page to manage all athletes.</h4>

      <table class="table table-fixed table-striped table-sm">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Tracked</th>
            <th scope="col">Tags</th>
            <th scope="col">Country</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="resource in resources" :key="resource.id">
            <td><div style="cursor: pointer;" @click.stop="$router.push({ path: `/athletes/${resource.id}`})">{{ resource.id}}</div></td>
            <td>{{ resource.lastName }}, {{ resource.firstName }}</td>
            <td>{{ resource.tracked }}</td>
            <td>
                <div v-for="tag in resource.tags" :key="tag" class="badge bg-secondary my-auto mx-1" style="height: 20px;">{{tag}}</div>
            </td>
            <td>{{ resource.country }}</td>
            <td>
              <div @click.stop="toggleTracked(resource.id)" class="btn btn-warning btn-xs" size="sm" variant="warning">
                <template v-if="resource.tracked">Un-track</template>
                <template v-else>Track</template>
              </div>
              <div @click.stop="selectedResourceId = resource.id; editModal.show()" class="ms-1 btn btn-primary btn-xs" size="sm" variant="info">Quick Edit</div>
              <div @click.stop="deleteAthlete(resource.id)" class="btn btn-danger btn-xs" size="sm" variant="danger">Delete</div>
            </td>
          </tr>
        </tbody>
      </table>
      <infinite-loading @infinite="infiniteHandler"></infinite-loading>

      <!-- Edit athlete modal -->
      <div class="modal fade" ref="editModal" tabindex="-1">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ selectedResource ? selectedResource.name || selectedResource.id : '' }}</h5>
              <button type="button" class="btn-close" aria-label="Close" @click="editModal.hide()"></button>
            </div>
            <div class="modal-body">
              todo
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="editModal.hide()">Close</button>
              <button type="button" class="btn btn-success" @click="save" :disabled="saving">Save <img v-if="saving" class="loader" src="@/assets/loader.gif"/></button>
            </div>
          </div>
        </div>
      </div>
    </main>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import { getAthletes, upsertAthlete } from "@/api";
import * as api from "@/api";
import SelectAthlete from "@/components/SelectAthlete";

export default {
  mixins: {  },
  components: {
    SelectAthlete
  },
  data() {
      return {
        errorLoading: null,
        isEditingName: false,
        editModal: null,
        resources: [],
        selectedResourceId: null,
        saving: false,
        page: 0,
      }
  },
  async mounted() {
    console.log("Athletes mounted");
    this.editModal = new bootstrap.Modal(this.$refs.editModal);
  },
  async destroyed() {
    console.log("Athletes destroyed");
  },
  computed: {
    selectedResource() { return this.resources.find(x => x.id == this.selectedResourceId) },
  },
  watch: {
  },
  methods: {
    async deleteAthlete(athleteId) {
      this.Swal.showLoading();
      await api.deleteAthlete(athleteId);
      this.Swal.close()
    },
    async save() {
      this.saving = true;
      await upsertAthlete(this.selectedResource.id);
      this.$store.commit("UPDATE_ATHLETE", this.selectedResource);
      this.saving = false;
    },
    async toggleTracked(resourceId) {
      this.Swal.showLoading();
      this.saving = true;
      const athleteIndex = this.resources.findIndex(x => x.id == resourceId);
      const athlete = this.resources[athleteIndex];

      athlete.tracked = !!!athlete.tracked; // !! converts to boolean !!! inverts

      // TODO: can error
      await upsertAthlete(athlete);
      this.$store.commit("UPDATE_ATHLETE", athlete);
      this.saving = false;
      this.Swal.close();
    },
    async infiniteHandler($state) {
      let { data } = await getAthletes({
        page: this.page,
      })

      if (data.athletes.length) {
        this.page += 1;
        this.resources.push(...data.athletes);
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  }
}
</script>

<style>

</style>